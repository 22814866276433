import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useFeaturesPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: featuresPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          peopleChat {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            message
          }
          paymentNotification {
            caption
            price
          }
        }
        discover {
          headline
          featureList {
            title
            description
          }
          productListCard {
            title
            products {
              image {
                src {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
                alt
              }
              title
              caption
            }
          }
          producersCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            caption
          }
          filterCard {
            title
            certificationList {
              title
              list {
                label
                isChecked
              }
            }
            industriesList {
              title
              list {
                label
                isChecked
              }
            }
            moreText
          }
          personalizedCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            caption
            description
            profileImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
          }
        }
        interact {
          chatsCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            message
          }
          meaningfulTimelinesCard {
            title
            cardImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            postDate
            caption
            description
          }
        }
        sell {
          productCatalogCard {
            products {
              image {
                src {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
                alt
              }
              title
              price
            }
          }
          getPaidCard {
            description
            list {
              text
            }
            caption
            price
          }
          orderingSystemCard {
            title
            caption
            list {
              text
            }
          }
        }
      }
      de: deFeaturesPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          peopleChat {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            message
          }
          paymentNotification {
            caption
            price
          }
        }
        discover {
          headline
          featureList {
            title
            description
          }
          productListCard {
            title
            products {
              image {
                src {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
                alt
              }
              title
              caption
            }
          }
          producersCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            caption
          }
          filterCard {
            title
            certificationList {
              title
              list {
                label
                isChecked
              }
            }
            industriesList {
              title
              list {
                label
                isChecked
              }
            }
            moreText
          }
          personalizedCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            caption
            description
            profileImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
          }
        }
        interact {
          chatsCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            message
          }
          meaningfulTimelinesCard {
            title
            cardImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            postDate
            caption
            description
          }
        }
        sell {
          productCatalogCard {
            products {
              image {
                src {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
                alt
              }
              title
              price
            }
          }
          getPaidCard {
            description
            list {
              text
            }
            caption
            price
          }
          orderingSystemCard {
            title
            caption
            list {
              text
            }
          }
        }
      }
    }
  `);

  return data[locale];
};
