import React from 'react';

// External Components

import {
  FeaturesSection,
  FeatureListContainer,
  FeatureListItem
} from 'components/section';

// Self Components
import {
  ProductCatalogCard,
  OrderingSystemCard,
  GetPaidCard
} from './components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Sell = ({
  staticData: { productCatalogCard, orderingSystemCard, getPaidCard },
  data: { headline, feature_list }
}) => {
  return (
    <FeaturesSection id="sell" headline={convertApiHtmlText(headline.html)}>
      <ListContainer
        featureList={feature_list}
        productCatalogCardData={productCatalogCard}
        getPaidCard={getPaidCard}
      />
    </FeaturesSection>
  );
};

const ListContainer = ({
  featureList,
  productCatalogCardData,
  getPaidCard
}) => {
  return (
    <FeatureListContainer>
      <ProductCatalog
        data={featureList[0]}
        productCatalogCardData={productCatalogCardData}
      />
      <OrderingSystem data={featureList[1]} getPaidCard={getPaidCard} />
    </FeatureListContainer>
  );
};

const ProductCatalog = ({
  data: { title, description },
  productCatalogCardData
}) => {
  return (
    <FeatureListItem title={title} description={description}>
      <ProductCatalogCard data={productCatalogCardData} />
    </FeatureListItem>
  );
};
const OrderingSystem = ({ data: { title, description }, getPaidCard }) => {
  return (
    <FeatureListItem title={title} description={description}>
      <GetPaidCard data={getPaidCard} />
    </FeatureListItem>
  );
};
