import React from 'react';

// Local Components
import { TimelineCard as BaseTimelineCard } from 'components';

export const TimelineCard = ({
  data: { caption, cardImage, description, postDate, title }
}) => {
  return (
    <BaseTimelineCard
      sx={{
        height: [null, null, null, null, '35.97rem', '35.96rem'],
        display: [null, null, null, null, 'flex', 'flex'],
        flexDirection: [null, null, null, null, 'column', 'column'],
        justifyContent: [null, null, null, null, 'center', 'center'],
        mr: [null, null, null, null, '-4.2rem', 0]
      }}
      caption={caption}
      cardImage={cardImage}
      description={description}
      postDate={postDate}
      title={title}
      hasBackground={false}
    />
  );
};
