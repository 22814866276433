import React from 'react';

// External Components
import {
  FeaturesSection,
  FeatureListContainer,
  FeatureListItem
} from 'components/section';

// Self Components
import {
  ProductListCard,
  FilterCard,
  ProducersCard,
  ProfileCard
} from './components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Discover = ({
  staticData: { productListCard, producersCard, filterCard, personalizedCard },
  data: { headline, feature_list }
}) => {
  return (
    <FeaturesSection id="discover" headline={convertApiHtmlText(headline.html)}>
      <ListContainer
        featureList={feature_list}
        productListCardData={productListCard}
        producersCard={producersCard}
        filterCardData={filterCard}
        personalizedCardData={personalizedCard}
      />
    </FeaturesSection>
  );
};

const ListContainer = ({
  featureList,
  productListCardData,
  producersCard,
  filterCardData,
  personalizedCardData
}) => {
  return (
    <FeatureListContainer>
      <SearchFeature
        data={featureList[0]}
        productListCardData={productListCardData}
      />
      <ProducersAround data={featureList[1]} producersCard={producersCard} />
      <FilterFeature data={featureList[2]} filterCardData={filterCardData} />
      <PersonalizeFeature
        data={featureList[3]}
        personalizedCardData={personalizedCardData}
      />
    </FeatureListContainer>
  );
};

const SearchFeature = ({
  data: { title, description },
  productListCardData
}) => {
  return (
    <FeatureListItem title={title} description={description}>
      <ProductListCard data={productListCardData} />
    </FeatureListItem>
  );
};

const ProducersAround = ({ data: { title, description }, producersCard }) => {
  return (
    <FeatureListItem title={title} description={description}>
      <ProducersCard data={producersCard} />
    </FeatureListItem>
  );
};

const FilterFeature = ({ data: { title, description }, filterCardData }) => {
  return (
    <FeatureListItem title={title} description={description}>
      <FilterCard data={filterCardData} />
    </FeatureListItem>
  );
};
const PersonalizeFeature = ({
  data: { title, description },
  personalizedCardData
}) => {
  return (
    <FeatureListItem title={title} description={description}>
      <ProfileCard data={personalizedCardData} />
    </FeatureListItem>
  );
};
