import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ProductCatalogItem } from 'components';

export const ProductCatalogCard = ({ data: { products }, ...props }) => {
  return (
    <Box
      className="__product-catalog-card"
      sx={{
        mt: ['3.2rem', null, 0, null, 0, 0]
      }}
      {...props}>
      {products.map(({ image, title, price }, index) => (
        <ProductCatalogItem
          key={index}
          image={image}
          title={title}
          price={price}
        />
      ))}
    </Box>
  );
};
