import React from 'react';

// External Components
import { Paragraph, Box } from '@thepuzzlers/pieces';
import { ImageContainer } from 'components';

export const ProductListCard = ({
  data: { title, products },
  sx,
  className = ''
}) => {
  return (
    <Box
      className={`__product-list-card ${className}`}
      sx={{
        bg: 'beige',
        borderRadius: 'small',
        p: [
          '2.4rem',
          '2.4rem 3.2rem 4rem 3.2rem',
          '2.4rem',
          '2.4rem',
          '2.4rem',
          '2.4rem'
        ],
        filter: ({ shadows }) => `drop-shadow(${shadows.card})`,
        mt: ['4.1rem', null, 0, 0, 0, 0],
        ...sx
      }}>
      <Paragraph
        className="__title"
        dangerouslySetInnerHTML={{ __html: title }}
        sx={{
          fontFamily: 'body.normal',
          fontSize: [
            '1.5rem',
            '1.7rem',
            '1.5rem',
            '1.5rem',
            '1.5rem',
            '1.5rem'
          ],
          lineHeight: 1,
          letterSpacing: '-0.01em',
          '& > span': {
            fontFamily: 'primary.bold'
          }
        }}
      />
      <ProductList products={products} />
    </Box>
  );
};

const ProductList = ({ products }) => {
  return (
    <Box
      className="__container"
      sx={{
        mt: ['3.2rem', '3.6rem', '3.2rem', '3.2rem', '3.2rem', '3.2rem']
      }}>
      {products.map((product, index) => (
        <ProductListItem key={index} data={product} />
      ))}
    </Box>
  );
};

const ProductListItem = ({
  data: {
    image: { src, alt },
    title,
    caption
  }
}) => {
  return (
    <Box
      className="__item"
      sx={{
        display: 'flex',
        alignItems: 'center',
        ':not(:first-of-type)': {
          mt: ['1.6rem', '2.4rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem']
        }
      }}>
      <ImageContainer
        src={src}
        alt={alt}
        sx={{
          width: ['5.6rem', '6.4rem', '5.6rem', '5.6rem', '5.6rem', '5.6rem'],
          height: ['5.6rem', '6.4rem', '5.6rem', '5.6rem', '5.6rem', '5.6rem'],
          '& img': {
            borderRadius: 'small',
            filter: ({ shadows }) => `drop-shadow(${shadows.image})`
          }
        }}
      />
      <Box
        sx={{
          ml: '1.6rem'
        }}>
        <Paragraph
          className="__title"
          sx={{
            fontFamily: 'primary.normal',
            fontSize: [
              '1.5rem',
              '1.7rem',
              '1.5rem',
              '1.5rem',
              '1.5rem',
              '1.5rem'
            ],
            lineHeight: 1,
            letterSpacing: '-0.01em'
          }}>
          {title}
        </Paragraph>
        <Paragraph
          className="__caption"
          sx={{
            fontFamily: 'body.normal',
            fontSize: [
              '1.4rem',
              '1.5rem',
              '1.4rem',
              '1.4rem',
              '1.4rem',
              '1.4rem'
            ],
            letterSpacing: '-0.01em',
            lineHeight: 1,
            mt: ['0.4rem', null, '0.5rem', null, '0.5rem', '0.5rem']
          }}>
          {caption}
        </Paragraph>
      </Box>
    </Box>
  );
};
