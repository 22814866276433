import React from 'react';

// External Components
import { Section, Heading, Paragraph, Image } from '@thepuzzlers/pieces';
import { Box } from '@thepuzzlers/pieces';

// Assets
import PinSvg from 'assets/svg/pins.svg';

// Local Components
import {
  GridSpacer,
  PaymentCard,
  ChatCardItem,
  CommaBlob,
  BowlingBar
} from 'components';
import { useSolutionPageData } from 'sections/solution';

export const Header = ({
  staticData: { peopleChat, paymentNotification },
  data: { headline, description }
}) => {
  return (
    // Markup
    <ContentWrapper>
      <TopSpacer />
      <Cards
        peopleChat={peopleChat}
        paymentNotification={paymentNotification}
      />
      <TextBlock headline={headline} description={description} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    as="header"
    id="landing-page__header"
    sx={{
      position: 'relative',
      overflow: ['hidden', 'hidden', 'hidden', 'hidden', 'visible', 'visible']
    }}>
    {children}
  </Section>
);

const Cards = ({
  peopleChat: { image, message },
  paymentNotification: { caption, price }
}) => {
  return (
    <Box
      className="__cards"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '10/ span 12',
          null,
          '15/ span 9',
          '14/ span 8'
        ],
        mt: ['11.9rem', null, '16.6rem', null, 0, '1.6rem'],
        position: 'relative',
        gridRow: [null, null, null, 2, 2, 2],
        zIndex: 1
      }}>
      <ChatCardItem
        image={image}
        message={message}
        sx={{
          ml: [null, null, 'auto', 0, 'auto', 'auto'],
          width: ['28.8rem', null, '31.8rem', null, '30.1rem', '30.8rem'],
          position: 'relative',
          zIndex: 1
        }}
      />
      <PaymentCard
        caption={caption}
        price={price}
        sx={{
          ml: ['auto', null, 0, null, 0, 0],
          mt: ['3.2rem', null, '3.9rem', null, '4.1rem', '3.7rem'],
          position: 'relative',
          zIndex: 1
        }}
      />

      {/* Decoration */}
      <CommaBlob
        sx={{
          width: '15.2rem',
          position: 'absolute',
          top: ['-3.1rem', null, '-7.3rem', null, '-6.5rem', '2rem'],
          right: ['-6.4rem', null, '-9rem', null, '-4.2rem', '-5.4rem']
        }}
      />
      <BowlingBar
        sx={{
          width: '2.85rem',
          position: 'absolute',
          transform: [
            'rotate(-15deg)',
            null,
            'rotate(-15deg)',
            null,
            'rotate(30deg)',
            'rotate(30deg)'
          ],
          top: ['15.2rem', null, '3.8rem', null, '10.4rem', '11.6rem'],
          left: ['1.2rem', null, '-12.8rem', null, '-1.2rem', '-2.9rem']
        }}
      />
    </Box>
  );
};

const TextBlock = ({ headline, description }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '1/ span 11',
          '1/ span 16',
          '1/ span 12',
          '1/ span 12',
          '1/ span 10'
        ],
        mt: ['6.8rem', null, '18.6rem', null, 0, 0],
        gridRow: [null, null, null, 2, 2, 2]
      }}>
      <Heading
        as="h1"
        sx={{
          fontFamily: 'primary.normal',
          fontSize: ['2.8rem', null, '4rem', null, '4rem', '4rem'],
          lineHeight: 1.15,
          letterSpacing: '-0.01em'
        }}>
        {headline}
      </Heading>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          fontSize: ['1.6rem', null, '1.8rem', null, '1.8rem', '1.8rem'],
          lineHeight: 1.5,
          mt: ['2.3rem', null, '2.8rem', null, '3.2rem', '4.8rem'],
          width: ['100%', null, '43.8rem', null, '43.8rem', '47rem'],
          letterSpacing: '-0.01em'
        }}>
        {description}
      </Paragraph>
    </Box>
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '18.1rem', '16.7rem']}
      sx={{
        display: ['none', 'none', 'none', 'block', 'block', 'block']
      }}
    />
  );
};
