import React from 'react';

// Assets
import { ProfileCard as ProfileCardDefault } from 'components';

export const ProfileCard = ({
  data: { caption, description, image, profileImage, title }
}) => {
  return (
    <ProfileCardDefault
      description={description}
      image={image}
      profileImage={profileImage}
      title={title}
      caption={caption}
      hasBackground={false}
      sx={{
        mt: ['4.7rem', null, 0, null, 0, 0],
        pb: [null, null, '3rem', null, 0, 0],
        height: [null, null, null, null, '37rem', '37rem'],
        display: [null, null, null, null, 'flex', 'flex'],
        flexDirection: [null, null, null, null, 'column', 'column'],
        justifyContent: 'center',
        mr: [null, null, null, null, '-4.2rem', 0],
        '& .__cover-image': {
          width: ['26.5rem', null, '100%', null, '32.8rem', '100%']
        }
      }}
    />
  );
};
