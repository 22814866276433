import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import {
  useFeaturesPageData,
  Header,
  Discover,
  Sell,
  Interact
} from 'sections/featuresPage';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';
import { Spacer } from 'components';

// Data
import { useKisFeaturesPageData } from 'graphqlHooks';
import { Cta } from 'components/section';

const FeaturesPage = () => {
  const { seo, header, discover, sell, interact } = useFeaturesPageData();

  const {
    header: cmsHeader,
    discover: cmsDiscover,
    interact: cmsInteract,
    sell: cmsSell
  } = useKisFeaturesPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header staticData={header} data={cmsHeader} />
      <Spacer height={['8.9rem', null, '8.6rem', null, '20.9rem', '17.2rem']} />
      <Box as="main">
        <Discover staticData={discover} data={cmsDiscover} />
        <Interact staticData={interact} data={cmsInteract} />
        <Sell staticData={sell} data={cmsSell} />
        <Spacer
          height={['11.2rem', null, '19.3rem', null, '31.3rem', '35.3rem']}
        />
        <Cta />
      </Box>
      <Spacer height={['8rem', null, '8rem', null, '6.3rem', '7.1rem']} />
    </>
  );
};

export default injectIntl(FeaturesPage);
