import React from 'react';

// Local Components
import { GetPaidCard as GetPaidCardDefault } from 'components';

export const GetPaidCard = ({
  data: { caption, description, list, price }
}) => {
  return (
    <GetPaidCardDefault
      sx={{
        mt: ['4.1rem', null, 0, null, 0, 0],
        height: [null, null, null, null, '28.8rem', '32rem'],
        display: [null, null, null, null, 'flex', 'flex'],
        flexDirection: [null, null, null, 'column', 'column'],
        justifyContent: [null, null, null, null, 'center', 'center']
      }}
      caption={caption}
      description={description}
      list={list}
      price={price}
    />
  );
};
