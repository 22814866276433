import React from 'react';

// External Components

import {
  FeaturesSection,
  FeatureListContainer,
  FeatureListItem
} from 'components/section';

// Self Components
import { ChatsCard, TimelineCard } from './components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Interact = ({
  staticData: { chatsCard, meaningfulTimelinesCard },
  data: { headline, feature_list }
}) => {
  return (
    <FeaturesSection
      id="interact"
      headline={convertApiHtmlText(headline.html)}
      sx={{
        bg: 'transparent'
      }}>
      <ListContainer
        featureList={feature_list}
        chatsCardData={chatsCard}
        meaningfulTimelinesCardData={meaningfulTimelinesCard}
      />
    </FeaturesSection>
  );
};

const ListContainer = ({
  featureList,
  chatsCardData,
  meaningfulTimelinesCardData
}) => {
  return (
    <FeatureListContainer>
      <InAppMessaging data={featureList[0]} chatsCardData={chatsCardData} />
      <MeaningfulTimelines
        data={featureList[1]}
        meaningfulTimelinesCardData={meaningfulTimelinesCardData}
      />
    </FeatureListContainer>
  );
};

const InAppMessaging = ({ data: { title, description }, chatsCardData }) => {
  return (
    <FeatureListItem title={title} description={description}>
      <ChatsCard data={chatsCardData} />
    </FeatureListItem>
  );
};
const MeaningfulTimelines = ({
  data: { title, description },
  meaningfulTimelinesCardData
}) => {
  return (
    <FeatureListItem title={title} description={description}>
      <TimelineCard data={meaningfulTimelinesCardData} />
    </FeatureListItem>
  );
};
