import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ProductCard } from 'components';

// assets
import { PointerTwo, RingTwo, ThinPointer } from 'components/svgs';

export const ProducersCard = ({ data }) => {
  return (
    <Box
      className="__producer-card-container"
      sx={{
        mt: ['5.6rem', null, 0, null, 0, 0],
        height: [null, null, null, null, '36.8rem', '36.8rem'],
        display: [null, null, null, null, 'flex', 'flex'],
        flexDirection: [null, null, null, null, 'column', 'column'],
        justifyContent: 'center',
        position: 'relative'
      }}>
      <FirstProducerCard data={data[0]} />
      <SecondProducerCard data={data[1]} />
      <ThirdProducerCard data={data[2]} />
    </Box>
  );
};

const FirstProducerCard = ({ data: { image, title, caption } }) => {
  return (
    <Box
      className="__first-producer-card"
      sx={{
        width: ['26.5rem', null, '100%', null, '26.5rem', '27rem'],
        ml: 'auto',
        position: 'relative'
      }}>
      <ProductCard
        image={image}
        title={title}
        caption={caption}
        sx={{
          position: 'relative',
          zIndex: 1
        }}
      />
      {/* Decoration */}
      <RingTwo
        sx={{
          position: 'absolute',
          width: ['7.6rem', null, '7.6rem', null, '7.6rem', '8rem', '8rem'],
          top: ['4.5rem', null, '4.5rem', null, '4.5rem', '4.5rem'],
          left: ['-2.7rem', null, '-2.7rem', null, '-2.7rem', '-2.7rem']
        }}
      />
    </Box>
  );
};
const SecondProducerCard = ({ data: { image, title, caption } }) => {
  return (
    <Box
      className="__second-producer-card"
      sx={{
        width: ['26.5rem', null, '100%', null, '26.5rem', '27.rem'],
        mt: '3.6rem',
        position: 'relative'
      }}>
      <ProductCard image={image} title={title} caption={caption} />
      {/* Decoration */}
      <PointerTwo
        sx={{
          position: 'absolute',
          width: ['2.4rem', null, '2.4rem', null, '2.4rem', '3rem', '3rem'],
          top: ['-1.7rem', null, '-1.7rem', null, '-1.7rem', '-1.7rem'],
          right: ['-1rem', null, '9.7rem', null, '-1rem', '-1rem']
        }}
      />
    </Box>
  );
};
const ThirdProducerCard = ({ data: { image, title, caption } }) => {
  return (
    <Box
      className="__third-producer-card"
      sx={{
        width: ['26.5rem', null, '100%', null, '26.5rem', '27rem'],
        mx: 'auto',
        mt: '3.6rem',
        position: 'relative'
      }}>
      <ProductCard image={image} title={title} caption={caption} />
      <ThinPointer
        sx={{
          position: 'absolute',
          width: ['1.7rem', null, '1.7rem', null, '1.7rem', '2rem', '2rem'],
          top: ['-1.8rem', null, '-1.8rem', null, '-1.8rem', '-1.8rem'],
          left: ['12.4rem', null, '9.7rem', null, '12.4rem', '12.4rem']
        }}
      />
    </Box>
  );
};
