import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ChatCardItem } from 'components';

export const ChatsCard = ({ data }) => {
  return (
    <Box
      sx={{
        mt: ['4.1rem', null, 0, null, 0, 0],
        // height: [null, null, null, null, '35rem', 'auto'],
        // display: [null, null, null, null, 'flex', 'flex'],
        // flexDirection: [null, null, null, null, 'column', 'column'],
        // justifyContent: [null, null, null, null, 'center', 'center'],
        ml: [null, null, '-3rem', null]
      }}>
      <FirstChat data={data[0]} />
      <SecondChat data={data[1]} />
      <ThirdChat data={data[2]} />
    </Box>
  );
};

const FirstChat = ({ data: { image, message } }) => {
  return (
    <ChatCardItem
      image={image}
      message={message}
      sx={{
        width: ['29.2rem', null, '31.8rem', null, '29.9rem', '30.1rem'],
        ml: ['auto', null, 'auto', null, 0, 0]
      }}
    />
  );
};
const SecondChat = ({ data: { image, message } }) => {
  return (
    <ChatCardItem
      image={image}
      message={message}
      sx={{
        width: ['29.2rem', null, '31.8rem', null, '29.9rem', '30.1rem'],
        mt: '2.4rem',
        ml: [null, null, null, null, 'auto', 'auto']
      }}
    />
  );
};
const ThirdChat = ({ data: { image, message } }) => {
  return (
    <ChatCardItem
      image={image}
      message={message}
      sx={{
        width: ['29.2rem', null, '31.8rem', null, '29.9rem', '30.1rem'],
        mt: '2.4rem',
        ml: ['auto', null, 'auto', null, 0, 0]
      }}
    />
  );
};
