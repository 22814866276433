import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Assets
import { UnCheckedCheckBox, CheckedCheckBox } from 'components/svgs';

export const FilterCard = ({
  data: { title, certificationList, industriesList, moreText }
}) => {
  return (
    <Box
      sx={{
        p: [
          '2.7rem 2.4rem 2.9rem 2.4rem',
          '2.7rem 2.4rem 4.4rem 2.4rem',
          '2.7rem 2.4rem 2.9rem 2.4rem',
          '2.7rem 2.4rem 2.9rem 2.4rem',
          '2.7rem 2.4rem 2.9rem 2.4rem',
          '2.7rem 2.4rem 2.9rem 2.4rem'
        ],
        bg: 'white',
        borderRadius: 'small',
        boxShadow: 'card',
        mt: ['4.1rem', null, 0, 0, 0, 0]
      }}>
      <Title title={title} />
      <CheckListWithTitle data={certificationList} />
      <CheckListWithTitle data={industriesList} />
      <FooterText text={moreText} />
    </Box>
  );
};

const Title = ({ title }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        fontSize: ['1.6rem', '1.8rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

const FooterText = ({ text }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.5,
        fontSize: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
        color: 'gray',
        mt: ['2.4rem', '3.2rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem']
      }}>
      {text}
    </Paragraph>
  );
};

// Reused Component
const CheckListWithTitle = ({ data: { title, list } }) => {
  return (
    <Box
      sx={{
        mt: ['2.4rem', '3.2rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem']
      }}>
      <Paragraph
        sx={{
          textTransform: 'uppercase',
          fontFamily: 'primary.normal',
          color: 'gray',
          // its weird with fontSize single value the phone sx do not get the correct value so we put to the fontsize to all breakpoints
          fontSize: [
            '1.1rem',
            '1.1rem',
            '1.1rem',
            '1.1rem',
            '1.1rem',
            '1.1rem'
          ],
          letterSpacing: '0.1em'
        }}>
        {title}
      </Paragraph>

      {list.map((item, index) => {
        return <CheckListItem key={index} data={item} />;
      })}
    </Box>
  );
};

const CheckListItem = ({ data: { label, isChecked } }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: '0.8rem',
        ':not(:first-of-type)': {
          mt: ['0.4rem', '0.8rem', '0.4rem', '0.4rem', '0.4rem', '0.4rem']
        }
      }}>
      <FakeCheckBox isChecked={isChecked} />
      <Paragraph
        sx={{
          ml: '1.5rem',
          fontFamily: isChecked ? 'primary.normal' : 'body.normal',
          color: isChecked ? 'primary' : 'gray',
          lineHeight: 1.5,
          fontSize: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem']
        }}>
        {label}
      </Paragraph>
    </Box>
  );
};

const FakeCheckBox = ({ isChecked }) => {
  return (
    <Box
      sx={{
        width: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
        height: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem']
      }}>
      {isChecked ? <CheckedCheckBox /> : <UnCheckedCheckBox />}
    </Box>
  );
};
